<ng-container *ngFor="let attribute of attributes">
  <ng-container *ngIf="attribute.is_primary; else secondaryAttribute">
    <div fxLayout="column" fxLayoutAlign="center" class="slider-wrap">
      <p class="no-margin">
        {{ attribute.name }} ({{ attribute.unit }}):
        <strong>{{ finalHeightValue }}</strong>
        <mat-progress-spinner
          *ngIf="(isAttributeSubmitting$ | async) && isHeightActive"
          mode="indeterminate"
          color="accent"
          [diameter]="20"
        ></mat-progress-spinner>
      </p>
      <ng-container *ngIf="disabledHeight === false; else sliderLoading">
        <mat-slider
          [max]="maxHeight"
          [min]="0"
          [step]="1"
          [showTickMarks]="true"
          [discrete]="true"
          [displayWith]="formatLabel.bind(this)"
          data-test="so_slider"
          data-cy="so-slider-height"
          [color]="'accent'"
          [disabled]="isAttributeSubmitting$ | async"
        >
          <input
            matSliderThumb
            [value]="attribute.values?.indexOf(selectedHeightValue)"
            (dragEnd)="onHeightSliderChange($event.value)"
            data-test="so_slider_thumb"
          />
        </mat-slider>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #secondaryAttribute>
    <div fxLayout="column" fxLayoutAlign="center" class="slider-wrap">
      <p *ngIf="disabledWidth">
        {{ 'sales_shared.configuration.select_height_first' | translate }}
      </p>
      <p class="no-margin">
        {{ attribute.name }} ({{ attribute.unit }}):
        <strong>{{ finalWidthValue }}</strong>
        <mat-progress-spinner
          *ngIf="(isAttributeSubmitting$ | async) && !isHeightActive"
          mode="indeterminate"
          color="accent"
          [diameter]="20"
        ></mat-progress-spinner>
      </p>

      <mat-slider
        [max]="maxWidth"
        [min]="0"
        [step]="1"
        [showTickMarks]="true"
        [discrete]="true"
        [displayWith]="formatWidthLabel.bind(this)"
        [disabled]="disabledWidth || (isAttributeSubmitting$ | async)"
        [color]="'accent'"
        data-test="so_slider"
        data-cy="so-slider-width"
      >
        <input
          matSliderThumb
          [value]="
            attribute.values?.indexOf(
              attribute.selected_value === widthValue
                ? attribute.selected_value
                : widthValue
            )
          "
          (dragEnd)="onWidthSliderChange($event.value)"
          data-test="so_slider_thumb"
        />
      </mat-slider>
    </div>
  </ng-template>
</ng-container>

<ng-template #sliderLoading>
  <ds-loading-placeholder
    [width]="25 | dsSpacing"
    [height]="2.4 | dsSpacing"
  ></ds-loading-placeholder>
</ng-template>
