import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  QueryList,
  TrackByFunction,
  ViewChildren,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatAccordion } from '@angular/material/expansion';
import { select, Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {
  Guide,
  SalesOptionCategories,
  SalesOptionCategory,
  SalesOptionGroup,
} from '@sales-libs/sc-configuration/data-access';
import { ScSharedSelectors } from '@sales-libs/shared/feature';
import { take } from 'rxjs';
import { SlScSalesOptionGroupComponent } from '../sales-option-group/sales-option-group.component';

export const imports = [
  SlScSalesOptionGroupComponent,
  MatAccordion,
  TranslateModule,
  CommonModule,
  FlexModule,
];

@Component({
  selector: 'sl-sc-sales-option-category-list',
  standalone: true,
  imports,
  templateUrl: './sales-option-category-list.component.html',
  styleUrls: ['./sales-option-category-list.component.scss'],
})
export class SlScSalesOptionCategoryListComponent implements OnInit {
  @Input()
  displayConfigurationDebuggingToggleFeatureFlag: string;
  @Input()
  salesOptionCategories: SalesOptionCategories;
  @Input()
  sectionName: string;
  userGuid: string;
  guide: Guide | undefined;
  displayConfigurationDebuggingToggle: boolean;

  @ViewChildren(MatAccordion)
  readonly Accordions: QueryList<MatAccordion>;

  trackByCategoryFn: TrackByFunction<SalesOptionCategory> = (
    index: number,
    item: SalesOptionCategory,
  ) => item.id;

  trackByGroupFn: TrackByFunction<SalesOptionGroup> = (
    index: number,
    item: SalesOptionGroup,
  ) => item.id;

  constructor(private store: Store) {
    this.store
      .pipe(select(ScSharedSelectors.guide), take(1))
      .subscribe((data) => {
        this.guide = data;
        this.userGuid = data?.user_guid ?? '';
      });
  }

  ngOnInit(): void {
    this.displayConfigurationDebuggingToggle =
      this.displayConfigurationDebuggingToggleFeatureFlag === 'true';
  }
}
