import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsCardCarouselComponent } from '@design-system/components/card-carousel';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {
  SalesOption,
  SalesOptionGroup,
  SalesOptionGroupType,
  SalesOptionSelectionType,
  SalesOptionState,
} from '@sales-libs/sc-configuration/data-access';
import { ScSharedActions, ScSharedSelectors } from '@sales-libs/shared/feature';
import {
  formatUrlFriendlyName,
  MediaType,
  SectionType,
  SlScDescriptionDecorationPipe,
} from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { Subject, take, takeUntil } from 'rxjs';
import { SlScConfigurationActions } from '../../store';
type SalesOptionType = SalesOption | null | undefined;

export const imports = [
  CommonModule,
  MatFormFieldModule,
  TranslateModule,
  SlScDescriptionDecorationPipe,
  MatCardModule,
  MatDialogModule,
  DsCardCarouselComponent,
  DsSpacingModule,
  FlexModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatButtonModule,
];

@Component({
  selector: 'sl-sc-sales-option-details-dialog',
  standalone: true,
  imports,
  templateUrl: './sales-option-details-dialog.component.html',
  styleUrls: ['./sales-option-details-dialog.component.scss'],
})
export class SlScSalesOptionDetailsDialogComponent
  implements OnInit, OnDestroy
{
  @Input() showAddButton: boolean;
  @Output()
  salesOptionSelected: EventEmitter<SalesOption> = new EventEmitter();
  readonly SalesOptionState = SalesOptionState;
  readonly MediaType = MediaType;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  configurationSalesOptions: SalesOptionType[];
  configurationSalesOptionsReversed: SalesOptionType[];
  configurationSalesOptionGroups:
    | (SalesOptionGroup | null | undefined)[]
    | undefined;
  private readonly destroy$ = new Subject<void>();
  isSalesOptionSubmitting = false;
  salesOptions: SalesOptionType[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { salesOption: SalesOption; sectionName: string },
    private store: Store,
    public dialogRef: MatDialogRef<SlScSalesOptionDetailsDialogComponent>,
    public actions: Actions,
  ) {}

  ngOnInit(): void {
    this.getSalesOptions();
  }

  addToConfiguration(salesOption: SalesOption) {
    this.salesOptionSelected.emit(salesOption);
    this.isSalesOptionSubmitting = true;

    this.actions
      .pipe(
        ofType(SlScConfigurationActions.saveSelectedSalesOptionSuccess),
        take(1),
      )
      .subscribe((guide) => {
        this.store.dispatch(ScSharedActions.SetGuide(guide));
        this.closeDialog();
      });
  }

  playVideo() {
    this.videoplayer?.nativeElement.play();
  }

  getConfigurationSalesOptionGroups() {
    this.store
      .pipe(select(ScSharedSelectors.guide), filterTruthy(), take(1))
      .subscribe((guide) => {
        this.configurationSalesOptionGroups = guide?.sections
          ?.filter(
            (section) =>
              section.type === SectionType.Configuration &&
              formatUrlFriendlyName(section.name) === this.data.sectionName,
          )
          ?.flatMap((section) =>
            section.sales_option_categories?.flatMap(
              (category) => category.sales_option_groups,
            ),
          )
          .filter(
            (group) => group?.type !== SalesOptionGroupType.AttributeBased,
          );
      });
  }

  getSalesOptions() {
    this.store
      .pipe(
        select(ScSharedSelectors.guide),
        filterTruthy(),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.getConfigurationSalesOptions();
        this.getConfigurationSalesOptionsReversed();
        this.isSalesOptionSubmitting = false;
      });
  }

  getConfigurationSalesOptions() {
    this.getConfigurationSalesOptionGroups();

    this.configurationSalesOptionGroups?.forEach((group) => {
      if (group?.subgroups && group?.subgroups?.length > 0) {
        group.subgroups.forEach((subgroup) => {
          if (subgroup.sales_options) {
            subgroup.sales_options.forEach((salesOption) => {
              this.salesOptions.push(salesOption);
              const childSalesOptions = salesOption.groups?.flatMap(
                (childGroup) => childGroup.sales_options,
              );
              this.salesOptions = this.salesOptions.concat(childSalesOptions);
            });
          }
        });
      } else if (group?.sales_options) {
        group.sales_options.forEach((salesOption) => {
          this.salesOptions.push(salesOption);
          if (salesOption.groups) {
            const childSalesOptions = salesOption.groups
              .filter(
                (childGroup) =>
                  childGroup.selection_type !== SalesOptionSelectionType.Card &&
                  childGroup.selection_type !==
                    SalesOptionSelectionType.Dropdown,
              )
              .flatMap((childGroup) => childGroup.sales_options);
            this.salesOptions = this.salesOptions.concat(childSalesOptions);
          }
        });
      }
    });

    this.configurationSalesOptions = this.salesOptions;
  }

  getConfigurationSalesOptionsReversed() {
    this.configurationSalesOptionsReversed = [
      ...this.configurationSalesOptions,
    ].reverse();
  }

  closeDialog(): void {
    this.dialogRef?.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
