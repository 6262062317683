import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Guide, Section, UserGuide } from '@sales-libs/sc-summary/data-access';
import { SlScSummaryComponent } from '@sales-libs/sc-summary/feature';
import { SectionType } from '@sales-libs/shared/data-access/sc-generated';
import { ScSharedActions } from '@sales-libs/shared/feature';
import { formatUrlFriendlyName } from '@sales-libs/shared/util';
import { take } from 'rxjs';
import { environment } from '../../environments/environment';

export const imports = [CommonModule, SlScSummaryComponent];

@Component({
  selector: 'tks-summary',
  standalone: true,
  imports: imports,
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  userGuid: string;
  guide: Guide;
  userGuide: UserGuide;
  productLineName: string;
  selectedLanguage: string;
  scUrl = environment.LINKS.TKS;
  sectionType = SectionType.Configuration;
  summarySection: Section | undefined;
  summarySectionName: string;
  stepName: string;

  readonly SectionType = SectionType;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    public actions: Actions,
  ) {}

  ngOnInit(): void {
    this.getUrlParameters();
  }

  getUrlParameters(): void {
    this.route.params.pipe(take(1)).subscribe((event) => {
      this.productLineName = event.productLine;
      this.userGuid = event.userGuid;
      this.selectedLanguage = event.language;

      this.updateSharedStore(
        this.productLineName,
        this.userGuid,
        event.stepName ? this.summarySection : undefined,
        this.selectedLanguage,
      );
    });
  }

  getSummarySection() {
    const configurationSections = this.guide?.sections?.filter(
      (section) => section.type === this.sectionType,
    );

    if (configurationSections) {
      this.summarySection =
        configurationSections[configurationSections?.length - 1] ?? '';
      this.summarySectionName =
        configurationSections[
          configurationSections?.length - 1
        ]?.name?.toLowerCase() ?? '';
    }
  }

  updateSharedStore(
    productLineName: string,
    userGuid: string,
    currentSection: Section | undefined,
    selectedLanguageCode: string,
  ) {
    this.store.dispatch(
      ScSharedActions.SetScSharedState({
        productLineName,
        userGuid,
        currentSection,
        selectedLanguageCode,
      }),
    );
  }

  navigateToConfigurationStep(sectionName: string): void {
    const guid = this.userGuid || this.userGuide.user_guid;
    const sectionType = formatUrlFriendlyName(SectionType.Configuration);
    formatUrlFriendlyName(sectionName);

    this.router.navigateByUrl(
      `/${this.selectedLanguage}/${this.productLineName}/${guid}/${sectionType}/${sectionName}`,
    );

    const configurationSection = this.guide?.sections?.find(
      (section) => formatUrlFriendlyName(section.name) === sectionName,
    );

    this.updateSharedStore(
      this.productLineName,
      this.userGuid,
      configurationSection,
      this.selectedLanguage,
    );
  }

  handleStartNewConfiguration() {
    this.router.navigate(['/']);
  }
}
