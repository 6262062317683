import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { select, Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  Guide,
  SalesOption,
  SalesOptionGroup,
  SalesOptionGroupType,
  SalesOptionState,
} from '@sales-libs/sc-configuration/data-access';
import { ScSharedSelectors } from '@sales-libs/shared/feature';
import { take } from 'rxjs';
import { SlScConfigurationActions } from '../../store';
import { SlScSalesOptionSubgroupComponent } from '../sales-option-subgroup/sales-option-subgroup.component';

export const imports = [
  CommonModule,
  MatSelectModule,
  TranslateModule,
  MatIconModule,
  FormsModule,
  MatFormFieldModule,
];

@Component({
  selector: 'sl-sc-sales-option-dropdown',
  standalone: true,
  imports: [imports, forwardRef(() => SlScSalesOptionSubgroupComponent)],
  templateUrl: './sales-option-dropdown.component.html',
  styleUrls: ['./sales-option-dropdown.component.scss'],
})
export class SlScSalesOptionDropdownComponent implements OnInit {
  @Input()
  salesOptionGroup: SalesOptionGroup;
  @Input()
  userGuid: string;
  @Input()
  guide: Guide | undefined;
  selectedLanguage: string;
  selectedSalesOption: SalesOption | undefined;
  isDisabled: boolean | undefined;

  readonly StateEnum = SalesOptionState;
  readonly SalesOptionGroupTypeEnum = SalesOptionGroupType;
  oneSalesOptionIsRequired = false;

  constructor(
    private store: Store,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.getLanguageFromSharedStore();
    this.prepareDropdownData();
  }

  getLanguageFromSharedStore(): void {
    this.store
      .pipe(select(ScSharedSelectors.sharedState), take(1))
      .subscribe((state) => {
        this.selectedLanguage = state.scShared.selectedLanguageCode;
      });
  }

  prepareDropdownData(): void {
    if (
      this.salesOptionGroup?.sales_options?.some(
        (salesOption) => salesOption.state === this.StateEnum.Required,
      )
    )
      this.oneSalesOptionIsRequired = true;

    this.isDisabled = this.salesOptionGroup?.sales_options?.every(
      (salesOption: SalesOption) =>
        salesOption.state === this.StateEnum.Invalid ||
        (salesOption.state !== this.StateEnum.Required &&
          this.oneSalesOptionIsRequired),
    );

    this.selectedSalesOption = this.salesOptionGroup?.sales_options?.find(
      (salesOption: SalesOption) =>
        salesOption.state === this.StateEnum.Selected ||
        salesOption.state === this.StateEnum.Required,
    );
  }

  selectSalesOption($event: MatSelectChange) {
    const selectedSalesOptionIds = { sales_options_ids: [$event.value.id] };

    this.store.dispatch(
      SlScConfigurationActions.saveSelectedSalesOption({
        guideId: this.guide?.id ?? 0,
        userGuid: this.userGuid,
        language: this.selectedLanguage,
        selectedSalesOptions: selectedSalesOptionIds,
        selectedSalesOptionName: $event.value.code,
      }),
    );
  }
}
