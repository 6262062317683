<h1 mat-dialog-title>
  {{ this.data.salesOption.name }}
</h1>
<div mat-dialog-content>
  <ng-container
    *ngIf="
      this.data.salesOption?.more_details?.content?.length !== 0;
      else descOnlyCard
    "
  >
    <ng-container
      *ngIf="this.data.salesOption?.more_details?.content?.length === 1"
    >
      <div *ngTemplateOutlet="cardContent"></div>
    </ng-container>
    <ng-container
      *ngIf="this.data.salesOption?.more_details?.content?.length !== 1"
    >
      <div class="card-carousel-wrap" data-test="sl_sc_so_carousel">
        <ds-card-carousel [isSingleCard]="true">
          <div *ngTemplateOutlet="cardContent"></div>
        </ds-card-carousel>
      </div>
    </ng-container>
  </ng-container>
</div>
<mat-dialog-actions
  fxLayout="column wrap"
  fxLayoutAlign="center end"
  [fxLayoutGap]="0.5 | dsSpacing"
>
  <div fxLayout="row wrap">
    <button mat-button mat-dialog-close>
      {{ 'forms.btn_close' | translate }}
    </button>
    <ng-container *ngIf="showAddButton">
      <button
        mat-raised-button
        color="accent"
        [disabled]="
          this.data.salesOption.state === SalesOptionState.Invalid ||
          this.data.salesOption.state === SalesOptionState.Selected ||
          this.data.salesOption.state === SalesOptionState.Required
        "
        data-test="add_to_configuration_btn"
        (click)="addToConfiguration(this.data.salesOption)"
      >
        <mat-icon *ngIf="isSalesOptionSubmitting"
          ><mat-progress-spinner
            [mode]="'indeterminate'"
            color="accent"
            [diameter]="20"
          >
          </mat-progress-spinner
        ></mat-icon>
        {{
          (this.data.salesOption.state === SalesOptionState.Selected ||
          this.data.salesOption.state === SalesOptionState.Required
            ? 'general.selected'
            : 'general.select'
          ) | translate
        }}
      </button>
    </ng-container>
  </div>
</mat-dialog-actions>

<ng-template #descOnlyCard>
  <mat-card>
    <mat-card-content class="answer-details-description">
      <div
        class="so-details"
        *ngIf="
          this.data.salesOption.more_details?.description ??
          this.data.salesOption?.description
        "
        [innerHtml]="
          this.data.salesOption.more_details?.description ??
            this.data.salesOption.description | slScDescriptionDecoration
        "
      ></div>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #cardContent>
  <mat-card
    *ngFor="let content of this.data.salesOption?.more_details?.content"
  >
    <img
      mat-card-image
      *ngIf="content.media_type === MediaType.Image"
      src="{{ content.media_uri }}"
      alt="{{ content?.description }}"
      data-test="sl_sc_so_image"
    />

    <video
      *ngIf="content.media_type === MediaType.Video"
      controls
      data-test="sl_sc_so_video"
      (play)="playVideo()"
      #videoPlayer
      class="so-img-video"
    >
      <source src="{{ content.media_uri }}" type="video/mp4" />
      {{ 'sales_shared.common.video_not_supported' | translate }}
    </video>

    <mat-card-content class="answer-details-description">
      <div
        class="so-details"
        *ngIf="content.description ?? this.data.salesOption?.description"
        [innerHtml]="
          content.description ?? this.data.salesOption?.description
            | slScDescriptionDecoration
        "
      ></div>
    </mat-card-content>
  </mat-card>
</ng-template>
