import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DsLoadingModule } from '@design-system/components/loading';
import {
  DsSnackbar,
  DsSnackbarModule,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ContactForm, Enumeration } from '@sales-libs/sc-summary/data-access';
import { Analytics, deformatUrlFriendlyName } from '@sales-libs/shared/util';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { PalfingerValidators } from '@utils/palfinger-validators';
import { Observable, Subject, takeUntil } from 'rxjs';
import { SlScSummaryActions, SlScSummarySelectors } from '../store';

export const imports = [
  CommonModule,
  TranslateModule,
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  FormsModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  GoogleAnalytics4DirectiveModule,
  DsLoadingModule,
  DsSnackbarModule,
  FlexLayoutModule,
  MatCheckboxModule,
];

@Component({
  selector: 'sl-sc-contact-form-dialog',
  standalone: true,
  imports: imports,
  templateUrl: './contact-form-dialog.component.html',
  styleUrl: './contact-form-dialog.component.scss',
})
export class SlScContactFormDialogComponent implements OnInit, OnDestroy {
  @ViewChild('scrollContainer') private scrollContainer: ElementRef;
  @ViewChild('contactFormContainer') private contactFormContainer: ElementRef;

  salutations$: Observable<Enumeration[] | null | undefined>;
  countries$: Observable<Enumeration[] | null | undefined>;
  selectedLanguage: string;
  guid: string;
  guideId: number;
  formDetails: string;
  messageCharLimit = 500;
  inputCharLimit = 200;
  postalCodeInputCharLimit = 50;
  formSubmitting = false;
  contactForm: FormGroup;

  readonly Analytics = Analytics;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    public actions: Actions,
    private formBuilder: FormBuilder,
    private _snackbar: DsSnackbar,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      guid: string;
      guideId: number;
      productLineName: string;
      scSelectedLanguage: string;
      scUrl?: string;
    },
    public dialogRef: MatDialogRef<SlScContactFormDialogComponent>,
    private _renderer: Renderer2,
  ) {
    this.guid = data.guid;
    this.guideId = data.guideId;
    this.selectedLanguage = data.scSelectedLanguage?.toUpperCase();

    this.contactForm = this.formBuilder.group({
      company_name: [
        '',
        [Validators.required, Validators.maxLength(this.inputCharLimit)],
      ],
      salutation: ['', Validators.required],
      first_name: [
        '',
        [Validators.required, Validators.maxLength(this.inputCharLimit)],
      ],
      last_name: [
        '',
        [Validators.required, Validators.maxLength(this.inputCharLimit)],
      ],
      job_title: ['', Validators.maxLength(this.inputCharLimit)],
      street: [
        '',
        [Validators.required, Validators.maxLength(this.inputCharLimit)],
      ],
      postal_code: [
        '',
        [
          Validators.required,
          Validators.maxLength(this.postalCodeInputCharLimit),
        ],
      ],
      city: [
        '',
        [Validators.required, Validators.maxLength(this.inputCharLimit)],
      ],
      country: ['', Validators.required],
      phone_number: ['', PalfingerValidators.phoneNumberValidator],
      email_address: ['', [Validators.required, Validators.email]],
      gdpr_consent_given: [false, Validators.requiredTrue],
      base_url: [data.scUrl],
      message: ['', [Validators.maxLength(this.messageCharLimit)]],
    });
  }

  ngOnInit() {
    this.initContactForm();
    this.getContactFormDetails();
  }

  initContactForm(): void {
    this.formDetails = this.translateService
      .instant('sales_shared.summary.contact_form.details')
      .replace('{0}', deformatUrlFriendlyName(this.data.productLineName));
  }

  getContactFormDetails() {
    this.store.dispatch(
      SlScSummaryActions.getContactFormDetails({
        language: this.selectedLanguage || 'EN',
      }),
    );

    this.getCountriesAndSalutations();
  }

  getCountriesAndSalutations(): void {
    this.actions
      .pipe(
        ofType(SlScSummaryActions.getContactFormDetailsSuccess),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.countries$ = this.store.pipe(
          select(SlScSummarySelectors.countries),
        );
        this.salutations$ = this.store.pipe(
          select(SlScSummarySelectors.salutations),
        );
      });
  }

  submitContactForm(): void {
    if (this.contactForm.valid) {
      this.formSubmitting = true;
      const contactFormValue = this.contactForm.getRawValue() as ContactForm;

      this.store.dispatch(
        SlScSummaryActions.submitContactForm({
          guideId: this.guideId,
          guid: this.guid,
          language: this.selectedLanguage,
          contactForm: contactFormValue,
        }),
      );

      this.actions
        .pipe(
          ofType(SlScSummaryActions.submitContactFormSuccess),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          this.formSubmitting = false;
          this.showSuccessMessage();
          this.closeDialog();
        });
    } else {
      this.contactForm.markAllAsTouched();
      this.contactForm?.controls?.gdpr_consent_given.markAsDirty();
      this.scrollToInvalidField();
    }
  }

  scrollToInvalidField(): void {
    const form = this.contactFormContainer.nativeElement;
    const firstInvalidControl = form?.getElementsByClassName('ng-invalid')[0];
    const invalidElementOffset = firstInvalidControl.offsetParent?.offsetTop;
    this._renderer.setProperty(
      this.scrollContainer.nativeElement,
      'scrollTop',
      invalidElementOffset,
    );
  }

  showSuccessMessage(): void {
    this._snackbar.queue(
      this.translateService.instant(
        'sales_shared.summary.contact_form.submit_success',
      ),
      {
        type: DsSnackbarType.Success,
        action: this.translateService.instant('general.close'),
      },
    );
  }

  closeDialog(): void {
    this.dialogRef?.close();
  }

  getInputErrorMessage(inputControl: AbstractControl): string {
    if (inputControl.value.length === 0) {
      return this.getInputRequiredErrorMessage();
    }

    return this.getInputCharRestrictionErrorMessage(inputControl);
  }

  getInputRequiredErrorMessage(): string {
    return this.translateService.instant('sales_shared.common.required');
  }

  getInputCharRestrictionErrorMessage(inputControl: AbstractControl): string {
    const requiredLength = inputControl.errors?.maxlength.requiredLength ?? 0;

    return this.translateService.instant('form_char_restriction', {
      limit: requiredLength,
      count: inputControl.value.length - requiredLength,
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
