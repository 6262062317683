<mat-form-field class="full-width">
  <mat-label>{{ 'sales_shared.common.please_select' | translate }}</mat-label>
  <mat-select
    [disabled]="isDisabled"
    (selectionChange)="selectSalesOption($event)"
    [(value)]="selectedSalesOption"
    data-test="so_select"
    [placeholder]="'sales_shared.common.please_select' | translate"
  >
    <mat-option
      *ngFor="let salesOption of salesOptionGroup.sales_options"
      [value]="salesOption"
    >
      {{ salesOption?.name }}
    </mat-option>
  </mat-select>
</mat-form-field>

<ng-container *ngFor="let salesOption of salesOptionGroup.sales_options">
  <ng-container *ngFor="let childGroup of salesOption.groups">
    <sl-sc-sales-option-subgroup
      *ngIf="childGroup.type === SalesOptionGroupTypeEnum.Child"
      [salesOptionGroup]="childGroup"
      [userGuid]="userGuid"
      [guide]="guide"
      [selectionType]="childGroup.selection_type"
      [subgroupType]="childGroup.type"
    >
    </sl-sc-sales-option-subgroup>
  </ng-container>
</ng-container>
