<div class="sl-sc-card-label-wrap">
  <mat-label *ngIf="!oneSalesOptionInGroupSelected" class="sl-sc-card-label">{{
    'sales_shared.common.please_select' | translate
  }}</mat-label>
</div>

<mat-radio-group
  fxLayout="row wrap"
  fxLayoutAlign="center flex-start"
  class="sl-sc-cards-wrap"
>
  <mat-radio-button
    *ngFor="let salesOption of salesOptionGroup.sales_options"
    class="sl-sc-card"
    value="{{ salesOption.code }}"
    [disabled]="isDisabled"
    [checked]="
      salesOption.state === StateEnum.Selected ||
      salesOption.state === StateEnum.Required
    "
    (change)="
      salesOption.state !== StateEnum.Invalid && selectSalesOption(salesOption)
    "
    disableRipple="true"
  >
    <span class="checkmark"></span>
    <div
      *ngIf="salesOption.color"
      class="color-box"
      [ngStyle]="{ 'background-color': salesOption.color }"
    ></div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="text-center">
      <strong>{{ salesOption.name }}</strong>
      <small>({{ salesOption.code }})</small>
    </div>
  </mat-radio-button>
</mat-radio-group>

<ng-container *ngFor="let salesOption of salesOptionGroup.sales_options">
  <ng-container *ngFor="let childGroup of salesOption.groups">
    <sl-sc-sales-option-subgroup
      *ngIf="childGroup.type === SalesOptionGroupTypeEnum.Child"
      [salesOptionGroup]="childGroup"
      [userGuid]="userGuid"
      [guide]="guide"
      [selectionType]="childGroup.selection_type"
      [subgroupType]="childGroup.type"
    >
    </sl-sc-sales-option-subgroup>
  </ng-container>
</ng-container>
